html, body {
    background-color:#e1dada;
    margin: 0;
    padding: 0;
   
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  
}

html {
    scroll-behavior: smooth;
  }


  .main-navigation {
    background-color:#ffffff;
    overflow: hidden;
   position: fixed;
    top: 0;
    width: 100%;
}

header {

    grid-column-start: 1;
    grid-column-end: -1;
}
a.navbar-brand {
    float: left;
    height: 35px;
    padding: 15px 15px;
    line-height: 20px;

}



nav.main-navigation ul.nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    text-align: right;
    margin-right: 30px;
}

.nav-list li.nav-list-item {
    display: inline-block;
    line-height: 40px;
    margin-left: 30px;
    margin-top: 15px;
}

a.nav-link {
    text-decoration: none;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    /* font-weight: 500; */
    cursor: pointer;
    position: relative;
    color: #000000;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        -webkit-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }


    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        pointer-events: auto;
        transition: cubic-bezier(0.4, 0, 0.2, 1);
    }
}

.nav-list li {
    animation: FadeIn 1s cubic-bezier(0.65, 0.05, 0.36, 1);
    animation-fill-mode: both;
}

.nav-list li:nth-child(1) {
    animation-delay: .3s;
}

.nav-list li:nth-child(2) {
    animation-delay: .6s;
}

.nav-list li:nth-child(3) {
    animation-delay: .9s;
}

.nav-list li:nth-child(4) {
    animation-delay: 1.2s;
}

.nav-list li:nth-child(5) {
    animation-delay: 1.5s;
}

/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.MobileNavigation{
    display: none;
}

@media(max-width: 990px) {
    
   .normalNavigation {
       display: none;
   }

   .MobileNavigation {
       display: flex;
       align-items: center;
  
   }
   .MobileNavigation .Hamburger {
       position: absolute; 
       right: 3%;
       cursor: pointer;
       padding-top: 50px;
   }

   nav.main-navigation ul.nav-list {
       display: flex;
       flex-direction: column;
       position: fixed;
       top: 65px;
       right: 0;
       width: 100%;
       background-color: #fff;
       border-top: 2px solid #2131c2;
   }

   nav.main-navigation ul.nav-list {
       margin-right: 0px;
   }

   a:hover{
       border-bottom: none;
       color: #2131c2;
   }

   ul li {
       padding-right: 5%;
   }
}
