


.password2 {
    margin-top: 30px;
}

.icon2 {

    padding-left: 10px;
    padding-top: 5px;
    min-width: 40px;
    margin-top: 28px;
}


@media(min-width: 1038px) {
    .imagen_recuperar{

       
        height:800px;
   width:2500px;
        
    
    }

    .container_recuperar {

        width: auto;
    }
    

}
