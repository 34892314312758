




.container_dashboard{
   
    position: relative; 
    height:800px;
   width:1350px;
}


.imagen_dashboard{

    background-image: url("https://mr360bucket.s3.amazonaws.com/mr360_images/colombia_mapa.png");
    background-repeat:no-repeat;
    background-size: 100%;
   height:800px;
   width:1500px;
   position: absolute;
    z-index: 1;

}


 .caja_bienvenida{
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    width: 600px;
    flex-direction: column;
    margin-top: 0px;
 }


 .encabezado_bienvenida {
    background-color:#4f7df2;
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    
}

.contenido_bienvenida {
    background-color: #fff;
    font-size: 22px;
    text-align: justify;
}


.caja_texto {

    padding: 20px;
    margin: 20px;

}

.calcula_microrred{
    padding-top: 20px;
    display: flex;
        flex-direction: column;
        align-items: center;
   
}

.btn_dashboard{

   font-family: Roboto, sans-serif;
   font-weight: bold;
   font-size: 20px;
   text-align: center;
   vertical-align: middle;
   color: #fff;
   background-color: #4f7df2;
   padding: 10px ;
   border: none;
   box-shadow: rgb(104, 104, 104) 2px 4px 12px -3px;
   border-radius: 14px;
   transition : 1000ms;
   cursor: pointer;
 
   }
   
   .btn_dashboard:hover{
   transition : 1000ms;
   background-color: #fff;
   color: #0066cc;
   
   }

   

   @media(min-width: 1038px) {
    .imagen_dashboard{

       
        height:800px;
   width:2500px;
        
    
    }

    .container_dashboard {

        width: auto;
    }
    

}

   @media(max-width: 768px) {
    .tarjeta_graficos {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width:auto;
    }

    .container_dashboard {
        
        width: auto;
    }


    .caja_bienvenida{
        width: 350px;
        margin-top: 0px;
        left: 50%;
    }

    .btn{
        font-size: 17px;
    }
}



@media(max-width: 500px) {
    .container_dashboard {
        
        width: auto;
    }


    .caja_bienvenida{
        width: 350px;
        margin-top: 0px;
        left: 50%;
    }

    .btn{
        font-size: 17px;
    }
}