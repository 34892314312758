
.container_login{
   
    position: relative; 
    height:800px;
   width:1350px;
}


.imagen_login{

    background-image: url("https://mr360bucket.s3.amazonaws.com/mr360_images/colombia_mapa.png");
    background-repeat:no-repeat;
    background-size: 100%;
   height:800px;
   width:1500px;
   filter: blur(2px);
   position: absolute;
    z-index: 1;

}


 .caja_login{
    position: absolute;
    z-index: 2;
    left: 55%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    width: 600px;
    flex-direction: column;
    margin-top: -100px;
 }

 .encabezado_login {
    background-color:#4f7df2;
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    
}

.contenido_login {
    background-color: #fff;
    font-size: 26px;
}


.input-icons i {
    position: absolute;
}



.icon {
    padding-left: 10px;
    padding-top: 5px;
    min-width: 40px;
}
.inputs {
    border-style: groove;
    border-color: #000000;
    border-radius: 1px;
    padding: 7px;
    width: 90%;
}

/* 
input{
    padding-left: 100px;
} */
.btn_iniciar{

    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background-color: #4f7df2;
    padding: 10px ;
    border: none;
    box-shadow: rgb(104, 104, 104) 2px 4px 12px -3px;
    border-radius: 14px;
    transition : 1000ms;
 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 38%;
    }


  input[type="email"], textarea {

    background-color : #c9e4ff;
    padding-left: 35px;
  }
  input[type="password"], textarea {

    background-color : #c9e4ff;
    padding-left: 35px;
  }




 
.caja_inputs {
    background-color:#c9e4ff;
    padding: 10px;
    margin: 20px;
}

.mantener{
   font-size: 26px;
}

.sin_cuenta{

    background-color:#c9e4ff;
    padding: 10px;
    padding-top: 20px;
    margin: 20px;
    text-align: center;
}
.subrayado{
    text-decoration: underline;
}

.olvido_contrasena{
    text-align: center;
    display: flex;
    flex-direction: column ;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}


.texto_olvido{
    margin-top: -20px;
    font-size: 26px;
}

@media(min-width: 1038px) {
    .imagen_login{

       
        height:800px;
   width:2500px;
        
    
    }

    .container_login {

        width: auto;
    }
    

}


@media(max-width: 768px) {
    .container_login {

        width: auto;
    }

    .caja_login{
        width: 550px;
        margin-top: 0px;
        left: 50%;
    }
    .btn{
        font-size: 17px;
    }
}



@media(max-width: 500px) {
    .container_login {

        width: auto;
    }

    .caja_login{
        width: 350px;
        margin-top: 0px;
        left: 50%;
    }
    .btn_iniciar{
        font-size: 17px;
        width: 120px;
    }
}