.container_formulario{
    flex: 1;
}

.container_dashboard{
   
    position: absolute; 
    height:800px;
   width:1350px;
}


/* .imagen_form{
    background-image: url("mapa_gers.png");
    background-repeat:no-repeat;
    background-size: 100%;
    height:700px;
    width:1350px;
    position: absolute;
    z-index: 1;

} */

.titulo {
    padding-left: 190px;
}
.cerrar_boton{
    padding-left: 140px;
    color:rgb(0, 0, 0);
}

 .caja_form{
    position: absolute;
    z-index: 2;
    left: 25%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    width: 550px;
    flex-direction: column;
    margin-top: 50px;
 }


 .encabezado_form {
    background-color:#4f7df2;
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.contenido_form {
    background-color: #fff;
    font-size: 22px;
  
}

.texto_formulario{
    padding-top: 40px;
    text-align: left;
    padding-left: 20px;
    margin-bottom: -40px;
}

.caja_formulario {
    background-color: #c9e4ff;
    padding: 20px;
    margin: 20px;

}

select {
    margin-top: 8px;
    background: #c9e4ff;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 7px;
    width: 100%;
    height: 30px;
    font-size: large;
  }


.calcula_microrred{
    padding-top: 20px;
    display: flex;
        flex-direction: column;
        align-items: center;
   
}

.btn_calcular{

   font-family: Roboto, sans-serif;
   font-weight: bold;
   font-size: 20px;
   text-align: center;
   vertical-align: middle;
   color: #fff;
   background-color: #4f7df2;
   padding: 10px ;
   margin-bottom: 0px;
   border: none;
   box-shadow: rgb(104, 104, 104) 2px 4px 12px -3px;
   border-radius: 14px;
   transition : 1000ms;
   cursor: pointer;
   width: 140px;
   
 
   }

   .nota_formulario{
       font-size: 12px;
       padding-top: 5px;
   }
   
   .btn_calcular:hover{
   transition : 1000ms;
   background-color: #fff;
   color: #0066cc;
   
   }

   
@media(max-width: 500px) {
    .container_login {
        
        width: auto;
    }


    .caja_login{
        width: 350px;
        margin-top: 0px;
        left: 50%;
    }
    .btn{
        font-size: 17px;
    }
}
