

.microred {
    height: 550px;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    padding: 40px;
    text-align: justify;
    
    text-align: justify;
    text-justify: inter-word;
    background-color:#c9e4ff;
  
}

.microred_clase {

padding: 50px;
margin-top: 0px;
}

.microred_clase h1 {

    
    margin-top: 40px;
    }

.microred_clase p {

    margin-top: -10px;
    }

.imagen_microrred{
    padding-right: 70px;
}

.boton_center {
    /* padding: 30px; */
     text-align: justify;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding-left: 35px; */
}


.btn{
 
   font-family:  'Montserrat', sans-serif;
   font-weight: 80;
   font-weight: bold;
   font-size: 20px;
   text-align: center;
   vertical-align: middle;
   color: #fff;
   background-color: #4f7df2;
   padding: 10px ;
   border: none;
   box-shadow: rgb(61, 61, 61) 2px 4px 12px -3px;
   border-radius: 14px;
   transition : 1000ms;

   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   cursor: pointer;
  
   }
   
   .btn:hover{
   transition : 1000ms;
   background-color: #fff;
   color: #0066cc;
   
   }

p.parrafo_microred {
    line-height: 1;
  }


.logomr360{
    position: absolute;
    margin-left: -420px;
    padding-top: 200px;
}

.flex-container{
    padding-top: 40px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.como_usar {height: 550px;
    font-size: 24px;
    font-family:  'Montserrat', sans-serif;
    text-align: justify;
    text-justify: inter-word;
    padding: 40px;
    margin-top: 15px;
    background-color:#ffffff;
    display: grid;
    grid-template-columns: 50% 50%;
    
    align-items: center;
    align-content: center;
}

.texto_como_usar{

    padding-right: 40px;
}

.como_usar_imagen {

    padding-left: 40px;
}

.que_obtiene {height: 500px;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    text-justify: inter-word;
    padding: 40px;
    margin-top: 15px;
    background-color:#c9e4ff;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2em;
    align-items: center;
    align-content: center;
}

.patrocinadores {
    height: 500px;
    font-size: 22px;
    font-family:  'Montserrat', sans-serif;
    padding: 30px;
    margin-top: 15px;
    background-color:#ffffff;
    grid-template-columns: 100%;
    gap: 1em;
}

.texto_patrocinadores{
    text-align: center;
    height: 30px;
}

h1{
    text-align: center;
}
.grid_patrocinadores {
    display: grid;
    grid-template-columns: 50% 50% ;
    align-items: center;
    align-items: baseline;
    justify-content: space-between;
    justify-content: center;
    justify-items: center;
    gap: 20px;
}

.convocatoria{
    width: 780px;
    padding-left: 200px;
}

.ganadores{
    width: 428px;
    padding-left: 120px;
}

.redes{

    flex-direction: column ;
}

h4{
    margin-bottom: 0;
}

.redes_sociales {

    display: flex;
    align-items: center;
    justify-content: center;
}

.logo_gers {

    margin-top: 15px;
}



.img_shadown {
    box-shadow: 15px 15px 8px 0 rgb(0 0 0 / 20%);

}



.flex-container-footer{
    display: flex;
    flex-direction: column;
 
}




@media(max-width: 768px) {
    .grid_patrocinadores {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}
  
  /* Tablets y pantallas más pequeñas */



@media(max-width: 768px) {
    .como_usar {
        display: flex;
        flex-direction: column;
        height: auto;
      
    }
}


@media(max-width: 1037px) {
    .que_obtiene {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
    }
}


@media(max-width: 768px) {
    .que_obtiene {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
    }
}


/*microred*/

@media(max-width: 1037px) {
    .flex-container {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
    }
}

@media(max-width: 1037px) {
    .microred {
        height: auto;
    }
}

@media(max-width: 768px) {
    .flex-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
    }
}

@media(max-width: 768px) {
    .microred {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .imagen_microrred{
        margin-left: 70px;
        margin-top: 50px;
    }
}

@media(max-width: 768px) {
    .patrocinadores {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 890px;
    }
}



@media(max-width: 768px) {
    .grid_patrocinadores {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}





@media(max-width: 631px) {
    .imagenes {
        width: 100%;
        max-width: 400px;
        height: auto;
    }
}

@media(max-width: 768px) {
    
    .nav-list {
        display: flex;
      flex-direction: column;
    }

    .texto_patrocinadores{
        text-align: center;
        height: 60px;
    }

    .grid_patrocinadores{
        gap:30px;
    }


    .ganadores{
        width: 500px;
        padding-left: 10px;
     }

     .convocatoria{
        width: 600px;
         padding-left: 10px;
    }

}


@media(max-width: 500px) {
    nav {
      height: 100px;
    }

    .nav .flex {
        flex-direction: column;
      }
}



@media(max-width: 500px) {
    .grid_patrocinadores {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }


    .ganadores{
       width: 350px; 
       padding-left: 10px;
    }
    .convocatoria{
        width: 350px;
        padding-left: 10px;
     }
}


@media(max-width: 500px) {
    .patrocinadores {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}

@media(max-width: 500px) {
    .btn {
        font-family:  'Montserrat', sans-serif;
        
        font-size: 16px;
   
   
   font-size: 15px;
   text-align: center;
   vertical-align: middle;
        font-size: 15px;
    }
}


@media(max-width: 500px) {
    .que_obtiene {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
    }
}


@media(max-width: 500px) {
    .como_usar {
        display: flex;
        flex-direction: column;
        height: auto;
      
    }
}