
.redes2{

    flex-direction: column ;
}

h4{
    margin-bottom: 0;
}

.redes_sociales2 {

    display: flex;
    align-items: center;
    justify-content: center;
}

.logo_gers2 {

    margin-top: 15px;
}



.footer2 { 
    
    margin-top: auto;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: #2131c2;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    text-align: center;
    justify-content:space-evenly;
    position: relative;
    bottom: 0px;
    z-index: 5;
    background-color:#e1dada;
    width: 100%;
}

.flex-container-footer2{
    display: flex;
    flex-direction: column;
}

@media(max-width: 768px) {
    .footer2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}

@media(max-width: 500px) {
    .logos_footer2{
      width: 50px;
    }
}
