.container_singup{
   
    position: relative; 
    height:900px;
   width:1350px;
}


.imagen_singup{

    background-image: url("https://mr360bucket.s3.amazonaws.com/mr360_images/colombia_mapa.png");
    background-repeat:no-repeat;
    background-size: 100%;
   height:900px;
   width:1500px;
   filter: blur(2px);
   position: absolute;
    z-index: 1;

}

.inputs_singup {
    border-style: groove;
    border-color: #000000;
    border-radius: 1px;
    padding: 7px;
    width: 96%;
}


 .caja_singup{
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    width: 600px;
    flex-direction: column;
    margin-top: 0px;
    
 }

 .encabezado_singup {
    background-color:#4f7df2;
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
   
}

.contenido_singup {
    background-color: #fff;
    font-size: 22px;
}



input[type="text"], textarea {

    background-color :#c9e4ff;
    font-size: 21px;
  }


  textarea:focus, input:focus {
    background-color: #edf6ff;
}

textarea:focus, input:focus {
    background-color: #edf6ff;
}

  input[type="password"].password_singup {

    background-color : #c9e4ff;
    padding-left: 5px;
    font-size: 21px;
  }

  input[type="email"].emailL_singup {

    background-color : #c9e4ff;
    padding-left: 5px;
    font-size: 21px;
  }

  input[type="number"], textarea {

    background-color : #c9e4ff;
    font-size: 21px;
  }

 
.caja_inputs {
    background-color:#c9e4ff;
    padding: 10px;
    margin: 20px;
}

.politicas{ 
    font-size: 10px;
    line-height : 80% ;
}


.crear_cuenta{
    text-align: center;
    display: flex;
    flex-direction: column ;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.btn_registrar{

   font-family: Roboto, sans-serif;
   font-weight: bold;
   font-size: 20px;
   text-align: center;
   vertical-align: middle;
   color: #fff;
   background-color: #4f7df2;
   padding: 10px ;
   border: none;
   box-shadow: rgb(104, 104, 104) 2px 4px 12px -3px;
   border-radius: 14px;
   transition : 1000ms;

   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   width: 43%;
   }
   
   .btn_registrar:hover{
   transition : 1000ms;
   background-color: #fff;
   color: #0066cc;
   
   }

   

.error_formulario{
    color: red;
    font-size: 14px;
}




@media(min-width: 1038px) {
    .imagen_singup{

       
        height:800px;
   width:2500px;
        
    
    }

    .container_singup {

        width: auto;
    }
    

}


   @media(max-width: 768px) {
    .container_singup {
        
        width: auto;
    }


    .caja_singup{
        width: 550px;
        margin-top: 0px;
        left: 50%;
    }

    
}




@media(max-width: 500px) {
    .container_singup {
        
        width: auto;
    }


    .caja_singup{
        width: 350px;
        margin-top: 0px;
        left: 10%;
    }
    .btn{
        font-size: 17px;
    }
    .caja_singup{

    padding-left: 300px;
    }
}